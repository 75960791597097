import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import SEO from "../components/seo"
import { resetMenu } from "../components/menu"
import { setGradient } from "../components/gradient"

const Posts = edges => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return <div>{Posts}</div>
}

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  resetMenu();
  setGradient({
    fromColor: '#f8f9f0',
    toColor: 'rgb(243 247 214)'
  })

  return (
    <Layout>
      <SEO title="Company" description="Learn all about the team behind Audyem and see current job listings." />
      <div className="max-w-3xl pt-6 md:pt-12 mx-auto relative z-10">
        <div className="max-w-lg mx-4">
          <h1 className="mb-8 font-semibold text-black">
            Company
          </h1>
          <p className="text-lg">
            Audyem was first launched in 2019 in Hamburg, Germany by software engineers Paul, Martin and Loïc to challenge the way content publishers engage with their audiences. Since then, Audyem has grown into a comprehensive toolkit ranging from referral strategies to polls and signup widgets serving over <u>100 million users</u> each month.
          </p>
          <Link to="/company/#positions">
          <button className="block text-sm mt-8 px-10 py-2 flex-shrink-0 transition duration-200 ease-in-out bg-black hover:bg-gray-900 text-white rounded-full inline cursor-pointer outline-none font-bold audyem-nudge" type="submit">
            Open Positions <span className="audyem-nudge-elem pr-3">→</span>
          </button>
          </Link>
        </div>
      </div>
      
      <div className="max-w-100 w-screen absolute audyem-bg-wave -mt-32 pt-32 pb-24 md:pb-32"></div>

      <div className="w-full bg-white mt-20 md:mt-16">
        <div className="max-w-3xl mx-auto relative z-10 pb-16">
          <div className="max-w-xl pb-12 mx-4">
            <h2 className="mb-4 font-bold text-black">
              Press
            </h2>
            <div className="text-base text-gray-800">
              {Posts(edges)}
            </div>
          </div>
          <div className="max-w-xl mx-4">
            <a id="positions" href="#positions"> </a>
            <h2 className="mb-4 font-bold text-black">
              Jobs
            </h2>
            <h3 className="text-base mb-4 text-black font-bold">
              Data Scientist (Remote)
            </h3>
            <p className="mb-4">
              Are you a highly self-motivated data scientist who wants to help publishers achieve their business goals using regression analysis and smart ETL strategies? Then get in touch with us, because we are always looking for new talent.
            </p>
            <p className="mb-4">
              We offer: An exciting environment with smart coworkers, complex data layers in a modern AWS stack and stock options in an organically growing company. We also provide you with the hardware you need: the Audyem team works remotely.
            </p>
            <p className="mb-12">
              Send your documents including a CV, portfolio or a link to your Github/Gitlab profile to <a className="text-black" href="mailto:join@audyem.com&subject=Bewerbung+als+Data+Scientist/in">join@audyem.com</a>. We'd love to hear from you!
            </p>

            <h3 className="mb-4 text-black font-bold">
              SaaS Sales Manager (Remote)
            </h3>
            <p className="mb-4">
              You are a sales manager with a strong sense of responsibility and want to help publishers achieve their business goals with the help of machine learning and smart data analysis? Then get in touch with us, because we are always looking for new talent.
            </p>
            <p className="mb-4">
              We offer: An exciting environment with smart coworkers, complex data layers in a modern AWS stack and stock options in an organically growing company. We also provide you with the hardware you need: the Audyem team works remotely.
            </p>
            <p className="mb-4">
              Send your documents and CV to <a className="text-black" href="mailto:join@audyem.com&subject=Bewerbung+als+Data+Scientist/in">join@audyem.com</a>. We'd love to hear from you!
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`